<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<h2 mat-dialog-title class="headerColor text-center">Feedback Details</h2>

<mat-divider>
</mat-divider>
<mat-dialog-content>
    <!-- <p>{{this.userEmail}}</p> -->
    <div>
        <div>
            <div class="d-flex example-full-width my-2">
                <label>{{data.type ==='App-Feedback'? 'User Name:':'Practitioner Name:'}}</label>
                <div class="ps-2">{{data.type ==='App-Feedback'? data.rowData.userName :data.rowData.practitionerName}}
                </div>
            </div>
            <div *ngIf="data.type ==='User-Feedback'" class="d-flex example-full-width my-2">
                <label>Patient Name:</label>
                <div class="ps-2">{{data.rowData.patientName}}</div>
            </div>
            <div *ngIf="data.type ==='App-Feedback'" class="d-flex example-full-width my-2">
                <label>Rating:</label>
                <div class="ps-2" [ngClass]="{
                    'text-success': data?.rowData?.rating >=4.5,
                    'text-warning': (data?.rowData?.rating >=3 && data?.rowData?.rating <4.5) ,
                    'text-danger': (data?.rowData?.rating >=1 && data?.rowData?.rating <3) ,
                  }">{{data.rowData.rating}}</div>
            </div>
            <div *ngIf="data.type ==='User-Feedback'" class="d-flex example-full-width my-2">
                <label>Rating:</label>
                <div class="ps-2" [ngClass]="{
                    'text-success': data?.rowData?.ratings >=4.5,
                    'text-warning': (data?.rowData?.ratings >=3 && data?.rowData?.ratings <4.5) ,
                    'text-danger': (data?.rowData?.ratings >=1 && data?.rowData?.ratings <3) ,
                  }">{{data.rowData.ratings}}</div>
            </div>
            <div *ngIf="data.type ==='App-Feedback'" class="d-flex example-full-width my-2">
                <label>User Type:</label>
                <div class="ps-2">{{data.rowData.userType | titlecase}}</div>
            </div>
            <div class="d-flex example-full-width my-2">
                <label>User Feedback:</label>
                <div class="ps-2 text-break">{{data.rowData.message}}</div>
            </div>
        </div>

    </div>


</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="close()">Close</button>
</mat-dialog-actions>