import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-show-user-feedback',
  templateUrl: './show-user-feedback.component.html',
  styleUrl: './show-user-feedback.component.css'
})
export class ShowUserFeedbackComponent implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<ShowUserFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToasterService
  ) {

  }

  ngOnInit(): void {
    console.log(this.data); 
  }

  close() {
    this.dialogRef.close();
  }
}